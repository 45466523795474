export const countriesObj = {
  AF: {
    label: "Afghanistan",
    calling_code: "+93",
    currency_code: "AFN",
    timezone: "Asia/Kabul",
  },
  AL: {
    label: "Albania",
    calling_code: "+355",
    currency_code: "ALL",
    timezone: "Europe/Tirane",
  },
  DZ: {
    label: "Algeria",
    calling_code: "+213",
    currency_code: "DZD",
    timezone: "Africa/Algiers",
  },
  AS: {
    label: "American Samoa",
    calling_code: "+1684",
    currency_code: "USD",
    timezone: "Pacific/Pago_Pago",
  },
  AD: {
    label: "Andorra",
    calling_code: "+376",
    currency_code: "EUR",
    timezone: "Europe/Andorra",
  },
  AO: {
    label: "Angola",
    calling_code: "+244",
    currency_code: "AOA",
    timezone: "Africa/Luanda",
  },
  AI: {
    label: "Anguilla",
    calling_code: "+1264",
    currency_code: "XCD",
    timezone: "America/Anguilla",
  },
  AQ: {
    label: "Antarctica",
    calling_code: "+672",
    currency_code: "XCD",
    timezone: "Antarctica/Palmer",
  },
  AG: {
    label: "Antigua and Barbuda",
    calling_code: "+1268",
    currency_code: "XCD",
    timezone: "America/Antigua",
  },
  AR: {
    label: "Argentina",
    calling_code: "+54",
    currency_code: "ARS",
    timezone: "America/Argentina/Buenos_Aires",
  },
  AM: {
    label: "Armenia",
    calling_code: "+374",
    currency_code: "AMD",
    timezone: "Asia/Yerevan",
  },
  AW: {
    label: "Aruba",
    calling_code: "+297",
    currency_code: "AWG",
    timezone: "America/Aruba",
  },
  AU: {
    label: "Australia",
    calling_code: "+61",
    currency_code: "AUD",
    timezone: "Australia/Sydney",
  },
  AT: {
    label: "Austria",
    calling_code: "+43",
    currency_code: "EUR",
    timezone: "Europe/Vienna",
  },
  AZ: {
    label: "Azerbaijan",
    calling_code: "+994",
    currency_code: "AZN",
    timezone: "Asia/Baku",
  },
  BS: {
    label: "Bahamas",
    calling_code: "+1242",
    currency_code: "BSD",
    timezone: "America/Nassau",
  },
  BH: {
    label: "Bahrain",
    calling_code: "+973",
    currency_code: "BHD",
    timezone: "Asia/Bahrain",
  },
  BD: {
    label: "Bangladesh",
    calling_code: "+880",
    currency_code: "BDT",
    timezone: "Asia/Dhaka",
  },
  BB: {
    label: "Barbados",
    calling_code: "+1246",
    currency_code: "BBD",
    timezone: "America/Barbados",
  },
  BY: {
    label: "Belarus",
    calling_code: "+375",
    currency_code: "BYR",
    timezone: "Europe/Minsk",
  },
  BE: {
    label: "Belgium",
    calling_code: "+32",
    currency_code: "EUR",
    timezone: "Europe/Brussels",
  },
  BZ: {
    label: "Belize",
    calling_code: "+501",
    currency_code: "BZD",
    timezone: "America/Belize",
  },
  BJ: {
    label: "Benin",
    calling_code: "+229",
    currency_code: "XOF",
    timezone: "Africa/Porto-Novo",
  },
  BM: {
    label: "Bermuda",
    calling_code: "+1441",
    currency_code: "BMD",
    timezone: "Atlantic/Bermuda",
  },
  BT: {
    label: "Bhutan",
    calling_code: "+975",
    currency_code: "BTN",
    timezone: "Asia/Thimphu",
  },
  BO: {
    label: "Bolivia",
    calling_code: "+591",
    currency_code: "BOB",
    timezone: "America/La_Paz",
  },
  BA: {
    label: "Bosnia and Herzegovina",
    calling_code: "+387",
    currency_code: "BAM",
    timezone: "Europe/Sarajevo",
  },

  BW: {
    label: "Botswana",
    calling_code: "+267",
    currency_code: "BWP",
    timezone: "Africa/Gaborone",
  },
  BV: {
    label: "Bouvet Island",
    calling_code: "+55",
    currency_code: "NOK",
    timezone: "Europe/Oslo",
  },
  BR: {
    label: "Brazil",
    calling_code: "+55",
    currency_code: "BRL",
    timezone: [
      "America/Sao_Paulo",
      "America/Manaus",
      "America/Recife",
      "America/Fortaleza",
      "America/Belem",
      "America/Cuiaba",
      "America/Porto_Velho",
      "America/Rio_Branco",
    ],
  },
  IO: {
    label: "British Indian Ocean Territory",
    calling_code: "+246",
    currency_code: "USD",
    timezone: "Indian/Chagos",
  },
  BN: {
    label: "Brunei",
    calling_code: "+673",
    currency_code: "BND",
    timezone: "Asia/Brunei",
  },
  BG: {
    label: "Bulgaria",
    calling_code: "+359",
    currency_code: "BGN",
    timezone: "Europe/Sofia",
  },
  BF: {
    label: "Burkina Faso",
    calling_code: "+226",
    currency_code: "XOF",
    timezone: "Africa/Ouagadougou",
  },
  BI: {
    label: "Burundi",
    calling_code: "+257",
    currency_code: "BIF",
    timezone: "Africa/Bujumbura",
  },
  KH: {
    label: "Cambodia",
    calling_code: "+855",
    currency_code: "KHR",
    timezone: "Asia/Phnom_Penh",
  },
  CM: {
    label: "Cameroon",
    calling_code: "+237",
    currency_code: "XAF",
    timezone: "Africa/Douala",
  },
  CA: {
    label: "Canada",
    calling_code: "+1",
    currency_code: "CAD",
    timezone: [
      "America/Toronto",
      "America/Vancouver",
      "America/Edmonton",
      "America/Winnipeg",
      "America/Halifax",
      "America/St_Johns",
      "America/Regina",
      "America/Whitehorse",
      "America/Dawson",
      "America/Iqaluit",
      "America/Rankin_Inlet",
      "America/Inuvik",
    ],
  },
  CV: {
    label: "Cape Verde",
    calling_code: "+238",
    currency_code: "CVE",
    timezone: "Atlantic/Cape_Verde",
  },
  KY: {
    label: "Cayman Islands",
    calling_code: "+1345",
    currency_code: "KYD",
    timezone: "America/Cayman",
  },
  CF: {
    label: "Central African Republic",
    calling_code: "+236",
    currency_code: "XAF",
    timezone: "Africa/Bangui",
  },
  TD: {
    label: "Chad",
    calling_code: "+235",
    currency_code: "XAF",
    timezone: "Africa/Ndjamena",
  },
  CL: {
    label: "Chile",
    calling_code: "+56",
    currency_code: "CLP",
    timezone: "America/Santiago",
  },
  CN: {
    label: "China",
    calling_code: "+86",
    currency_code: "CNY",
    timezone: "Asia/Shanghai",
  },
  CX: {
    label: "Christmas Island",
    calling_code: "+61",
    currency_code: "AUD",
    timezone: "Indian/Christmas",
  },
  CC: {
    label: "Cocos (Keeling) Islands",
    calling_code: "+61",
    currency_code: "AUD",
    timezone: "Indian/Cocos",
  },
  CO: {
    label: "Colombia",
    calling_code: "+57",
    currency_code: "COP",
    timezone: "America/Bogota",
  },
  KM: {
    label: "Comoros",
    calling_code: "+269",
    currency_code: "KMF",
    timezone: "Indian/Comoro",
  },
  CG: {
    label: "Congo",
    calling_code: "+242",
    currency_code: "XAF",
    timezone: "Africa/Brazzaville",
  },
  CK: {
    label: "Cook Islands",
    calling_code: "+682",
    currency_code: "NZD",
    timezone: "Pacific/Rarotonga",
  },
  CR: {
    label: "Costa Rica",
    calling_code: "+506",
    currency_code: "CRC",
    timezone: "America/Costa_Rica",
  },
  HR: {
    label: "Croatia",
    calling_code: "+385",
    currency_code: "HRK",
    timezone: "Europe/Zagreb",
  },
  CU: {
    label: "Cuba",
    calling_code: "+53",
    currency_code: "CUP",
    timezone: "America/Havana",
  },
  CY: {
    label: "Cyprus",
    calling_code: "+357",
    currency_code: "EUR",
    timezone: "Asia/Nicosia",
  },
  CZ: {
    label: "Czech Republic",
    calling_code: "+420",
    currency_code: "CZK",
    timezone: "Europe/Prague",
  },
  DK: {
    label: "Denmark",
    calling_code: "+45",
    currency_code: "DKK",
    timezone: "Europe/Copenhagen",
  },
  DJ: {
    label: "Djibouti",
    calling_code: "+253",
    currency_code: "DJF",
    timezone: "Africa/Djibouti",
  },
  DM: {
    label: "Dominica",
    calling_code: "+1767",
    currency_code: "XCD",
    timezone: "America/Dominica",
  },
  DO: {
    label: "Dominican Republic",
    calling_code: "+1849",
    currency_code: "DOP",
    timezone: "America/Santo_Domingo",
  },
  TP: {
    label: "East Timor",
    calling_code: "+670",
    currency_code: "USD",
    timezone: "Asia/Dili",
  },
  EC: {
    label: "Ecuador",
    calling_code: "+593",
    currency_code: "USD",
    timezone: ["America/Guayaquil", "Pacific/Galapagos"],
  },
  EG: {
    label: "Egypt",
    calling_code: "+20",
    currency_code: "EGP",
    timezone: "Africa/Cairo",
  },
  SV: {
    label: "El Salvador",
    calling_code: "+503",
    currency_code: "SVC",
    timezone: "America/El_Salvador",
  },
  GQ: {
    label: "Equatorial Guinea",
    calling_code: "+240",
    currency_code: "XAF",
    timezone: "Africa/Malabo",
  },
  ER: {
    label: "Eritrea",
    calling_code: "+291",
    currency_code: "ERN",
    timezone: "Africa/Asmara",
  },
  EE: {
    label: "Estonia",
    calling_code: "+372",
    currency_code: "EUR",
    timezone: "Europe/Tallinn",
  },
  ET: {
    label: "Ethiopia",
    calling_code: "+251",
    currency_code: "ETB",
    timezone: "Africa/Addis_Ababa",
  },
  FK: {
    label: "Falkland Islands",
    calling_code: "+500",
    currency_code: "FKP",
    timezone: "Atlantic/Stanley",
  },
  FO: {
    label: "Faroe Islands",
    calling_code: "+298",
    currency_code: "DKK",
    timezone: "Atlantic/Faroe",
  },
  FJ: {
    label: "Fiji Islands",
    calling_code: "+679",
    currency_code: "FJD",
    timezone: "Pacific/Fiji",
  },
  FI: {
    label: "Finland",
    calling_code: "+358",
    currency_code: "EUR",
    timezone: "Europe/Helsinki",
  },
  FR: {
    label: "France",
    calling_code: "+33",
    currency_code: "EUR",
    timezone: "Europe/Paris",
  },
  GF: {
    label: "French Guiana",
    calling_code: "+594",
    currency_code: "EUR",
    timezone: "America/Cayenne",
  },
  PF: {
    label: "French Polynesia",
    calling_code: "+689",
    currency_code: "XPF",
    timezone: "Pacific/Tahiti",
  },
  TF: {
    label: "French Southern territories",
    calling_code: "+262",
    currency_code: "EUR",
    timezone: "Indian/Kerguelen",
  },

  GA: {
    label: "Gabon",
    calling_code: "+241",
    currency_code: "XAF",
    timezone: "Africa/Libreville",
  },
  GM: {
    label: "Gambia",
    calling_code: "+220",
    currency_code: "GMD",
    timezone: "Africa/Banjul",
  },
  GE: {
    label: "Georgia",
    calling_code: "+995",
    currency_code: "GEL",
    timezone: "Asia/Tbilisi",
  },
  DE: {
    label: "Germany",
    calling_code: "+49",
    currency_code: "EUR",
    timezone: "Europe/Berlin",
  },
  GH: {
    label: "Ghana",
    calling_code: "+233",
    currency_code: "GHS",
    timezone: "Africa/Accra",
  },
  GI: {
    label: "Gibraltar",
    calling_code: "+350",
    currency_code: "GIP",
    timezone: "Europe/Gibraltar",
  },
  GR: {
    label: "Greece",
    calling_code: "+30",
    currency_code: "EUR",
    timezone: "Europe/Athens",
  },
  GL: {
    label: "Greenland",
    calling_code: "+299",
    currency_code: "DKK",
    timezone: "America/Nuuk",
  },
  GD: {
    label: "Grenada",
    calling_code: "+1473",
    currency_code: "XCD",
    timezone: "America/Grenada",
  },
  GP: {
    label: "Guadeloupe",
    calling_code: "+590",
    currency_code: "EUR",
    timezone: "America/Guadeloupe",
  },
  GU: {
    label: "Guam",
    calling_code: "+1671",
    currency_code: "USD",
    timezone: "Pacific/Guam",
  },
  GT: {
    label: "Guatemala",
    calling_code: "+502",
    currency_code: "QTQ",
    timezone: "America/Guatemala",
  },
  GG: {
    label: "Guernsey",
    calling_code: "+undefined",
    timezone: "Europe/Guernsey",
  },
  GN: {
    label: "Guinea",
    calling_code: "+224",
    currency_code: "GNF",
    timezone: "Africa/Conakry",
  },
  GW: {
    label: "Guinea-Bissau",
    calling_code: "+245",
    currency_code: "CFA",
    timezone: "Africa/Bissau",
  },
  GY: {
    label: "Guyana",
    calling_code: "+592",
    currency_code: "GYD",
    timezone: "America/Guyana",
  },
  HT: {
    label: "Haiti",
    calling_code: "+509",
    currency_code: "HTG",
    timezone: "America/Port-au-Prince",
  },
  HM: {
    label: "Heard Island and McDonald Islands",
    calling_code: "+672",
    currency_code: "AUD",
    timezone: "Indian/Kerguelen",
  },
  VA: {
    label: "Holy See (Vatican City State)",
    calling_code: "+379",
    currency_code: "EUR",
    timezone: "Europe/Vatican",
  },

  HN: {
    label: "Honduras",
    calling_code: "+504",
    currency_code: "HNL",
    timezone: "America/Tegucigalpa",
  },
  HK: {
    label: "Hong Kong",
    calling_code: "+852",
    currency_code: "HKD",
    timezone: "Asia/Hong_Kong",
  },
  HU: {
    label: "Hungary",
    calling_code: "+36",
    currency_code: "HUF",
    timezone: "Europe/Budapest",
  },
  IS: {
    label: "Iceland",
    calling_code: "+354",
    currency_code: "ISK",
    timezone: "Atlantic/Reykjavik",
  },
  IN: {
    label: "India",
    calling_code: "+91",
    currency_code: "INR",
    timezone: ["Asia/Calcutta", "Asia/Kolkata"],
  },
  ID: {
    label: "Indonesia",
    calling_code: "+62",
    currency_code: "IDR",
    timezone: "Asia/Jakarta",
  },
  IR: {
    label: "Iran",
    calling_code: "+98",
    currency_code: "IRR",
    timezone: "Asia/Tehran",
  },
  IQ: {
    label: "Iraq",
    calling_code: "+964",
    currency_code: "IQD",
    timezone: "Asia/Baghdad",
  },
  IE: {
    label: "Ireland",
    calling_code: "+353",
    currency_code: "EUR",
    timezone: "Europe/Dublin",
  },
  IM: {
    label: "Isle of Man",
    calling_code: "+44",
    timezone: "Europe/Isle_of_Man",
  },
  IL: {
    label: "Israel",
    calling_code: "+972",
    currency_code: "ILS",
    timezone: "Asia/Jerusalem",
  },
  IT: {
    label: "Italy",
    calling_code: "+39",
    currency_code: "EUR",
    timezone: "Europe/Rome",
  },
  CI: {
    label: "Ivory Coast",
    calling_code: "+225",
    currency_code: "XOF",
    timezone: "Africa/Abidjan",
  },
  JM: {
    label: "Jamaica",
    calling_code: "+1876",
    currency_code: "JMD",
    timezone: "America/Jamaica",
  },
  JP: {
    label: "Japan",
    calling_code: "+81",
    currency_code: "JPY",
    timezone: "Asia/Tokyo",
  },
  JE: { label: "Jersey", calling_code: "+44", timezone: "Europe/Jersey" },
  JO: {
    label: "Jordan",
    calling_code: "+962",
    currency_code: "JOD",
    timezone: "Asia/Amman",
  },

  KZ: {
    label: "Kazakhstan",
    calling_code: "+7",
    currency_code: "KZT",
    timezone: "Asia/Almaty",
  },
  KE: {
    label: "Kenya",
    calling_code: "+254",
    currency_code: "KES",
    timezone: "Africa/Nairobi",
  },
  KI: {
    label: "Kiribati",
    calling_code: "+686",
    currency_code: "AUD",
    timezone: "Pacific/Tarawa",
  },
  KW: {
    label: "Kuwait",
    calling_code: "+965",
    currency_code: "KWD",
    timezone: "Asia/Kuwait",
  },
  KG: {
    label: "Kyrgyzstan",
    calling_code: "+996",
    currency_code: "KGS",
    timezone: "Asia/Bishkek",
  },
  LA: {
    label: "Laos",
    calling_code: "+856",
    currency_code: "LAK",
    timezone: "Asia/Vientiane",
  },
  LV: {
    label: "Latvia",
    calling_code: "+371",
    currency_code: "LVL",
    timezone: "Europe/Riga",
  },
  LB: {
    label: "Lebanon",
    calling_code: "+961",
    currency_code: "LBP",
    timezone: "Asia/Beirut",
  },
  LS: {
    label: "Lesotho",
    calling_code: "+266",
    currency_code: "LSL",
    timezone: "Africa/Maseru",
  },
  LR: {
    label: "Liberia",
    calling_code: "+231",
    currency_code: "LRD",
    timezone: "Africa/Monrovia",
  },
  LY: {
    label: "Libyan Arab Jamahiriya",
    calling_code: "+218",
    currency_code: "LYD",
    timezone: "Africa/Tripoli",
  },
  LI: {
    label: "Liechtenstein",
    calling_code: "+423",
    currency_code: "CHF",
    timezone: "Europe/Vaduz",
  },
  LT: {
    label: "Lithuania",
    calling_code: "+370",
    currency_code: "LTL",
    timezone: "Europe/Vilnius",
  },
  LU: {
    label: "Luxembourg",
    calling_code: "+352",
    currency_code: "EUR",
    timezone: "Europe/Luxembourg",
  },
  MO: { label: "Macao", calling_code: "+853", timezone: "Asia/Macau" },
  MK: {
    label: "North Macedonia",
    calling_code: "+389",
    currency_code: "MKD",
    timezone: "Europe/Skopje",
  },
  MG: {
    label: "Madagascar",
    calling_code: "+261",
    currency_code: "MGF",
    timezone: "Indian/Antananarivo",
  },
  MW: {
    label: "Malawi",
    calling_code: "+265",
    currency_code: "MWK",
    timezone: "Africa/Blantyre",
  },
  MY: {
    label: "Malaysia",
    calling_code: "+60",
    currency_code: "MYR",
    timezone: "Asia/Kuala_Lumpur",
  },
  MV: {
    label: "Maldives",
    calling_code: "+960",
    currency_code: "MVR",
    timezone: "Indian/Maldives",
  },
  ML: {
    label: "Mali",
    calling_code: "+223",
    currency_code: "XOF",
    timezone: "Africa/Bamako",
  },
  MT: {
    label: "Malta",
    calling_code: "+356",
    currency_code: "EUR",
    timezone: "Europe/Malta",
  },
  MH: {
    label: "Marshall Islands",
    calling_code: "+692",
    currency_code: "USD",
    timezone: "Pacific/Majuro",
  },
  MQ: {
    label: "Martinique",
    calling_code: "+596",
    currency_code: "EUR",
    timezone: "America/Martinique",
  },
  MR: {
    label: "Mauritania",
    calling_code: "+222",
    currency_code: "MRO",
    timezone: "Africa/Nouakchott",
  },
  MU: {
    label: "Mauritius",
    calling_code: "+230",
    currency_code: "MUR",
    timezone: "Indian/Mauritius",
  },
  YT: {
    label: "Mayotte",
    calling_code: "+262",
    currency_code: "EUR",
    timezone: "Indian/Mayotte",
  },
  MX: {
    label: "Mexico",
    calling_code: "+52",
    currency_code: "MXN",
    timezone: [
      "America/Mexico_City",
      "America/Cancun",
      "America/Chihuahua",
      "America/Hermosillo",
      "America/Mazatlan",
      "America/Merida",
      "America/Monterrey",
      "America/Tijuana",
    ],
  },
  FM: {
    label: "Micronesia, Federated States of",
    calling_code: "+691",
    currency_code: "USD",
    timezone: "Pacific/Pohnpei",
  },
  MD: {
    label: "Moldova",
    calling_code: "+373",
    currency_code: "MDL",
    timezone: "Europe/Chisinau",
  },
  MC: {
    label: "Monaco",
    calling_code: "+377",
    currency_code: "EUR",
    timezone: "Europe/Monaco",
  },
  MN: {
    label: "Mongolia",
    calling_code: "+976",
    currency_code: "MNT",
    timezone: "Asia/Ulaanbaatar",
  },
  ME: {
    label: "Montenegro",
    calling_code: "+382",
    timezone: "Europe/Podgorica",
  },
  MS: {
    label: "Montserrat",
    calling_code: "+1664",
    currency_code: "XCD",
    timezone: "America/Montserrat",
  },
  MA: {
    label: "Morocco",
    calling_code: "+212",
    currency_code: "MAD",
    timezone: "Africa/Casablanca",
  },
  MZ: {
    label: "Mozambique",
    calling_code: "+258",
    currency_code: "MZN",
    timezone: "Africa/Maputo",
  },
  MM: {
    label: "Myanmar",
    calling_code: "+95",
    currency_code: "MMR",
    timezone: "Asia/Yangon",
  },
  NA: {
    label: "Namibia",
    calling_code: "+264",
    currency_code: "NAD",
    timezone: "Africa/Windhoek",
  },
  NR: {
    label: "Nauru",
    calling_code: "+674",
    currency_code: "AUD",
    timezone: "Pacific/Nauru",
  },
  NP: {
    label: "Nepal",
    calling_code: "+977",
    currency_code: "NPR",
    timezone: "Asia/Kathmandu",
  },
  NL: {
    label: "Netherlands",
    calling_code: "+31",
    currency_code: "EUR",
    timezone: "Europe/Amsterdam",
  },
  AN: {
    label: "Netherlands Antilles",
    calling_code: "+599",
    currency_code: "ANG",
    timezone: "America/Curacao",
  },
  NC: {
    label: "New Caledonia",
    calling_code: "+687",
    currency_code: "XPF",
    timezone: "Pacific/Noumea",
  },
  NZ: {
    label: "New Zealand",
    calling_code: "+64",
    currency_code: "NZD",
    timezone: "Pacific/Auckland",
  },
  NI: {
    label: "Nicaragua",
    calling_code: "+505",
    currency_code: "NIO",
    timezone: "America/Managua",
  },
  NE: {
    label: "Niger",
    calling_code: "+227",
    currency_code: "XOF",
    timezone: "Africa/Niamey",
  },
  NG: {
    label: "Nigeria",
    calling_code: "+234",
    currency_code: "NGN",
    timezone: "Africa/Lagos",
  },
  NU: {
    label: "Niue",
    calling_code: "+683",
    currency_code: "NZD",
    timezone: "Pacific/Niue",
  },
  NF: {
    label: "Norfolk Island",
    calling_code: "+672",
    currency_code: "AUD",
    timezone: "Pacific/Norfolk",
  },
  KP: {
    label: "North Korea",
    calling_code: "+850",
    currency_code: "KPW",
    timezone: "Asia/Pyongyang",
  },
  GB: {
    label: "Northern Ireland",
    calling_code: "+44",
    currency_code: "GBP",
    timezone: "Europe/London",
  },
  MP: {
    label: "Northern Mariana Islands",
    calling_code: "+1670",
    currency_code: "USD",
    timezone: "Pacific/Saipan",
  },
  NO: {
    label: "Norway",
    calling_code: "+47",
    currency_code: "NOK",
    timezone: "Europe/Oslo",
  },
  OM: {
    label: "Oman",
    calling_code: "+968",
    currency_code: "OMR",
    timezone: "Asia/Muscat",
  },
  PK: {
    label: "Pakistan",
    calling_code: "+92",
    currency_code: "PKR",
    timezone: "Asia/Karachi",
  },
  PW: {
    label: "Palau",
    calling_code: "+680",
    currency_code: "USD",
    timezone: "Pacific/Palau",
  },
  PS: {
    label: "Palestine",
    calling_code: "+970",
    currency_code: null,
    timezone: "Asia/Gaza",
  },
  PA: {
    label: "Panama",
    calling_code: "+507",
    currency_code: "PAB",
    timezone: "America/Panama",
  },
  PG: {
    label: "Papua New Guinea",
    calling_code: "+675",
    currency_code: "PGK",
    timezone: "Pacific/Port_Moresby",
  },
  PY: {
    label: "Paraguay",
    calling_code: "+595",
    currency_code: "PYG",
    timezone: "America/Asuncion",
  },
  PE: {
    label: "Peru",
    calling_code: "+51",
    currency_code: "PEN",
    timezone: "America/Lima",
  },
  PH: {
    label: "Philippines",
    calling_code: "+63",
    currency_code: "PHP",
    timezone: "Asia/Manila",
  },
  PN: { label: "Pitcairn", calling_code: "+64", timezone: "Pacific/Pitcairn" },
  PL: {
    label: "Poland",
    calling_code: "+48",
    currency_code: "PLN",
    timezone: "Europe/Warsaw",
  },
  PT: {
    label: "Portugal",
    calling_code: "+351",
    currency_code: "EUR",
    timezone: "Europe/Lisbon",
  },
  PR: {
    label: "Puerto Rico",
    calling_code: "+1939",
    currency_code: "USD",
    timezone: "America/Puerto_Rico",
  },
  QA: {
    label: "Qatar",
    calling_code: "+974",
    currency_code: "QAR",
    timezone: "Asia/Qatar",
  },
  RE: {
    label: "Reunion",
    calling_code: "+262",
    currency_code: "EUR",
    timezone: "Indian/Reunion",
  },
  RO: {
    label: "Romania",
    calling_code: "+40",
    currency_code: "RON",
    timezone: "Europe/Bucharest",
  },
  RU: {
    label: "Russian Federation",
    calling_code: "+7",
    currency_code: "RUB",
    timezone: "Europe/Moscow",
  },
  RW: {
    label: "Rwanda",
    calling_code: "+250",
    currency_code: "RWF",
    timezone: "Africa/Kigali",
  },
  SH: {
    label: "Saint Helena",
    calling_code: "+290",
    currency_code: "SHP",
    timezone: "Atlantic/St_Helena",
  },
  TT: {
    label: "Trinidad and Tobago",
    calling_code: "+1",
    currency_code: "TTD",
    timezone: "America/Port_of_Spain",
  },
  KN: {
    label: "Saint Kitts and Nevis",
    calling_code: "+1869",
    currency_code: "XCD",
    timezone: "America/St_Kitts",
  },
  LC: {
    label: "Saint Lucia",
    calling_code: "+1758",
    currency_code: "XCD",
    timezone: "America/St_Lucia",
  },
  PM: {
    label: "Saint Pierre and Miquelon",
    calling_code: "+508",
    currency_code: "EUR",
    timezone: "America/Miquelon",
  },
  VC: {
    label: "Saint Vincent and the Grenadines",
    calling_code: "+1784",
    currency_code: "XCD",
    timezone: "America/St_Vincent",
  },
  WS: {
    label: "Samoa",
    calling_code: "+685",
    currency_code: "WST",
    timezone: "Pacific/Apia",
  },
  SM: {
    label: "San Marino",
    calling_code: "+378",
    currency_code: "EUR",
    timezone: "Europe/San_Marino",
  },
  ST: {
    label: "Sao Tome and Principe",
    calling_code: "+239",
    currency_code: "STD",
    timezone: "Africa/Sao_Tome",
  },
  SA: {
    label: "Saudi Arabia",
    calling_code: "+966",
    currency_code: "SAR",
    timezone: "Asia/Riyadh",
  },
  SN: {
    label: "Senegal",
    calling_code: "+221",
    currency_code: "XOF",
    timezone: "Africa/Dakar",
  },
  RS: {
    label: "Serbia",
    calling_code: "+381",
    currency_code: "RSD",
    timezone: "Europe/Belgrade",
  },
  SC: {
    label: "Seychelles",
    calling_code: "+248",
    currency_code: "SCR",
    timezone: "Indian/Mahe",
  },
  SL: {
    label: "Sierra Leone",
    calling_code: "+232",
    currency_code: "SLL",
    timezone: "Africa/Freetown",
  },
  SG: {
    label: "Singapore",
    calling_code: "+65",
    currency_code: "SGD",
    timezone: "Asia/Singapore",
  },
  SK: {
    label: "Slovakia",
    calling_code: "+421",
    currency_code: "EUR",
    timezone: "Europe/Bratislava",
  },
  SI: {
    label: "Slovenia",
    calling_code: "+386",
    currency_code: "EUR",
    timezone: "Europe/Ljubljana",
  },
  SB: {
    label: "Solomon Islands",
    calling_code: "+677",
    currency_code: "SBD",
    timezone: "Pacific/Guadalcanal",
  },
  SO: {
    label: "Somalia",
    calling_code: "+252",
    currency_code: "SOS",
    timezone: "Africa/Mogadishu",
  },
  ZA: {
    label: "South Africa",
    calling_code: "+27",
    currency_code: "ZAR",
    timezone: "Africa/Johannesburg",
  },
  KR: {
    label: "South Korea",
    calling_code: "+82",
    currency_code: "KRW",
    timezone: "Asia/Seoul",
  },
  SS: {
    label: "South Sudan",
    calling_code: "+211",
    currency_code: "SSP",
    timezone: "Africa/Juba",
  },
  ES: {
    label: "Spain",
    calling_code: "+34",
    currency_code: "EUR",
    timezone: "Europe/Madrid",
  },
  LK: {
    label: "Sri Lanka",
    calling_code: "+94",
    currency_code: "LKR",
    timezone: "Asia/Colombo",
  },
  SD: {
    label: "Sudan",
    calling_code: "+249",
    currency_code: "SDG",
    timezone: "Africa/Khartoum",
  },
  SR: {
    label: "Suriname",
    calling_code: "+597",
    currency_code: "SRD",
    timezone: "America/Paramaribo",
  },
  SZ: {
    label: "Swaziland",
    calling_code: "+268",
    currency_code: "SZL",
    timezone: "Africa/Mbabane",
  },
  SE: {
    label: "Sweden",
    calling_code: "+46",
    currency_code: "SEK",
    timezone: "Europe/Stockholm",
  },
  CH: {
    label: "Switzerland",
    calling_code: "+41",
    currency_code: "CHF",
    timezone: "Europe/Zurich",
  },
  SY: {
    label: "Syria",
    calling_code: "+963",
    currency_code: "SYP",
    timezone: "Asia/Damascus",
  },
  TJ: {
    label: "Tajikistan",
    calling_code: "+992",
    currency_code: "TJS",
    timezone: "Asia/Dushanbe",
  },
  TZ: {
    label: "Tanzania",
    calling_code: "+255",
    currency_code: "TZS",
    timezone: "Africa/Dar_es_Salaam",
  },
  TH: {
    label: "Thailand",
    calling_code: "+66",
    currency_code: "THB",
    timezone: "Asia/Bangkok",
  },
  TN: {
    label: "Tunisia",
    calling_code: "+216",
    currency_code: "TND",
    timezone: "Africa/Tunis",
  },
  TR: {
    label: "Turkey",
    calling_code: "+90",
    currency_code: "TRY",
    timezone: "Europe/Istanbul",
  },
  TM: {
    label: "Turkmenistan",
    calling_code: "+993",
    currency_code: "TMT",
    timezone: "Asia/Ashgabat",
  },
  UG: {
    label: "Uganda",
    calling_code: "+256",
    currency_code: "UGX",
    timezone: "Africa/Kampala",
  },
  UA: {
    label: "Ukraine",
    calling_code: "+380",
    currency_code: "UAH",
    timezone: "Europe/Kiev",
  },
  AE: {
    label: "United Arab Emirates",
    calling_code: "+971",
    currency_code: "AED",
    timezone: "Asia/Dubai",
  },
  UK: {
    label: "United Kingdom",
    calling_code: "+44",
    currency_code: "GBP",
    timezone: "Europe/London",
  },
  US: {
    label: "United States",
    calling_code: "+1",
    currency_code: "USD",
    timezone: [
      "America/New_York",
      "America/Chicago",
      "America/Denver",
      "America/Los_Angeles",
      "America/Phoenix",
      "America/Anchorage",
      "America/Juneau",
      "America/Metlakatla",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
      "Pacific/Honolulu",
    ],
  },
  UY: {
    label: "Uruguay",
    calling_code: "+598",
    currency_code: "UYU",
    timezone: "America/Montevideo",
  },

  UZ: {
    label: "Uzbekistan",
    calling_code: "+998",
    currency_code: "UZS",
    timezone: "Asia/Tashkent",
  },
  VU: {
    label: "Vanuatu",
    calling_code: "+678",
    currency_code: "VUV",
    timezone: "Pacific/Efate",
  },
  VE: {
    label: "Venezuela",
    calling_code: "+58",
    currency_code: "VEF",
    timezone: "America/Caracas",
  },
  VN: {
    label: "Vietnam",
    calling_code: "+84",
    currency_code: "VND",
    timezone: "Asia/Ho_Chi_Minh",
  },
  VG: {
    label: "Virgin Islands, British",
    calling_code: "+1",
    currency_code: "USD",
    timezone: "America/Tortola",
  },
  VI: {
    label: "Virgin Islands, U.S.",
    calling_code: "+1",
    currency_code: "USD",
    timezone: "America/St_Thomas",
  },
  WF: {
    label: "Wallis and Futuna",
    calling_code: "+681",
    currency_code: "XPF",
    timezone: "Pacific/Wallis",
  },
  EH: {
    label: "Western Sahara",
    calling_code: "+212",
    currency_code: "MAD",
    timezone: "Africa/El_Aaiun",
  },
  YE: {
    label: "Yemen",
    calling_code: "+967",
    currency_code: "YER",
    timezone: "Asia/Aden",
  },
  ZM: {
    label: "Zambia",
    calling_code: "+260",
    currency_code: "ZMW",
    timezone: "Africa/Lusaka",
  },
  ZW: {
    label: "Zimbabwe",
    calling_code: "+263",
    currency_code: "ZWD",
    timezone: "Africa/Harare",
  },
};

export const getCountriesDisplayList = () => {
  let countries = [];

  Object.keys(countriesObj || {}).forEach((code) => {
    countries.push({
      id: code,
      label: countriesObj[code].label,
    });
  });

  return countries;
};
